@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SKF Chevin';
    src: url('./assets/fonts/SKFChevin-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SKF Chevin Medium';
    src: url('./assets/fonts/SKFChevinOT-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}
@layer components {
  .tabLeft a {
    display: flex;
    justify-content: left;
  }

  .tabLeft .resetTabLeft a {
    display: inline;
  }

  .tabNoPadding > div:last-child {
    padding: 0;
  }
  .chevron {
    position: relative;
    display: block;
    height: 0; /*Height should be double border thickness*/
  }
  .chevron::before,
  .chevron::after {
    position: absolute;
    display: block;
    content: '';
    border: 20px solid transparent; /*Adjust chevron size*/
  }
  /*Change the four instances of 'top' below to rotate chevron*/
  /*Use (top/right/bottom/left) where you want the back of the chevron to be*/
  .chevron::before {
    top: 0;
    border-top-color: #048942; /*Chevron Color*/
  }
  .chevron::after {
    top: -3px; /*Adjust thickness*/
    border-top-color: #fff; /*Match chevron background colour*/
  }

  @media screen and (max-width: 768px) {
    .languageBorder {
      margin-top: 12px;
    }
  }

  svg {
    pointer-events: none; /* useOuterClick with svg's will not work without this */
  }
}

.print p {
  display: inline;
}

.testing {
  height: 1rem;
}
